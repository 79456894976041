<template>
  <div class="px-16 py-24 desk:px-24">
    <div
      :class="{
        'bg-lightest p-24': design === 'page'
      }"
    >
      <div>
        <div v-if="design === 'page'" class="mb-16 type-headline-xl">{{ $t('impersonateModal.search') }}</div>
        <TextInput
          v-model="companyCode"
          class="w-full"
          type="text"
          :label="$t('impersonateModal.customerSuccess.placeholder')"
        />
      </div>
      <div v-if="listLoading" class="flex justify-center my-16">
        <LoadingIcon />
      </div>
      <div v-else-if="customers?.length" class="pt-24 flex flex-col">
        <div
          v-for="customer in customers"
          :key="customer.code"
          class="flex justify-between"
          :class="{
            'border-b border-light last:border-b-0': design === 'page'
          }"
        >
          <div
            class="flex mb-24"
          >
            <div class="rounded-full bg-brandPrimary w-40 h-40 mr-12 flex justify-center items-center">
              <img src="/icons/smiley.svg" class="h-24 w-24">
            </div>
            <div>
              <div class="type-sm-medium">
                {{ customer.name }}
              </div>
              <div class="type-xs-medium text-dark">
                {{ customer.code }}
              </div>
            </div>
          </div>
          <div v-if="design === 'page' && customer.useInvoiceAddressAsDeliveryAddress">
            <div class="type-sm">
              {{ customer.invoiceAddress?.line1 }}
            </div>
            <div class="type-sm">
              {{ customer.invoiceAddress?.zip }} {{ customer.invoiceAddress?.city }}
            </div>
          </div>
          <div v-else-if="design === 'page' && customer.deliveryAddresses && customer.deliveryAddresses[0]">
            <div class="type-sm">
              {{ customer.deliveryAddresses[0].line1 }}
            </div>
            <div class="type-sm">
              {{ customer.deliveryAddresses[0].zip }} {{ customer.deliveryAddresses[0].city }}
            </div>
          </div>
          <div v-if="design === 'page'">
            <div class="type-sm">
              {{ customer.phone }}
            </div>
            <div class="type-sm">
              <a :href="`mailto:${customer.email}`" class="underline">
                {{ customer.email }}
              </a>
            </div>
          </div>
          <button
            class="btn"
            :class="{
              'loading': loading
            }"
            @click="impersonate(customer.id)"
          >
            {{ $t('impersonateModal.customerSuccess.cta') }}
          </button>
        </div>
      </div>
      <div v-else-if="error" class="type-sm-medium mt-16">
        {{ $t('impersonateModal.customerSuccess.error') }}
      </div>
    </div>
    <div
      v-if="selectedCustomer"
      class="pt-16"
      :class="{
        'border-light border-t': customers?.length && design !== 'page',
        'bg-lightest p-24 mt-32': design === 'page',
      }"
    >
      <div v-if="!customers?.length || design === 'page'" class="mt-12">
        {{ $t('impersonateModal.customerSuccess.loggedInAs') }}:
      </div>
      <div v-if="!customers?.length || design === 'page'" class="pt-12 flex justify-between">
        <div
          class="flex mb-24 cursor-pointer"
        >
          <div class="rounded-full bg-brandPrimary w-40 h-40 mr-12 flex justify-center items-center">
            <img src="/icons/smiley.svg" class="h-24 w-24">
          </div>
          <div>
            <div class="type-sm-medium">
              {{ selectedCustomer.name }}
            </div>
            <div class="type-xs-medium text-dark">
              {{ selectedCustomer.code }}
            </div>
          </div>
        </div>
        <button
          class="btn"
          :class="{
            'loading': loading
          }"
          @click="stopImpersonate"
        >
          {{ $t('impersonateModal.customerSuccess.stopImpersonating') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useUserStore } from '~/store/user';
import { Company } from '~/constants/types/norce';
import useApiFetch from '~/composeables/useApiFetch';
import debounce from '~/util/debounce';
import { storeToRefs } from 'pinia';
import TextInput from '~/components/form-elements/TextInput.vue';
import useLinkReplacer from '~/composeables/useLinkReplacer';

interface Props {
  design?: 'modal' | 'page';
}

withDefaults(defineProps<Props>(), {
  design: 'modal',
});

const userStore = useUserStore();
const { userProfile } = storeToRefs(userStore);
const { apiGet, handleApiError } = useApiFetch();
const { manualUrl } = useLinkReplacer();

const companyCode = ref('');
const loading = ref(false);
const listLoading = ref(false);
const error = ref(false);
const customers = ref<Company[] | null>(null);
const selectedCustomer = ref<Company | null>(null);

const getCustomer = async() => {
  if (companyCode.value) {
    listLoading.value = true;
    const res = await apiGet<Company[]>(`/customer-success/company/${companyCode.value}`);
    if (res) {
      customers.value = res;
    } else {
      error.value = true;
      customers.value = null;
    }
    listLoading.value = false;
  } else {
    customers.value = null;
  }
};

const impersonate = async(id: number) => {
  loading.value = true;
  const res = await userStore.impersonateCompany(id);
  if (res) {
    window.location.assign(manualUrl('/account/overview'));
    //reload();
  } else {
    handleApiError();
    loading.value = false;
  }
};

const stopImpersonate = async() => {
  loading.value = true;
  await userStore.stopImpersonatingCompany();
  reload();
};

const reload = () => {
  window.location.reload();
};

onMounted(() => {
  if (userProfile.value.impersonatingCompany) {
    selectedCustomer.value = userProfile.value.company;
  }
});

watch(
  companyCode,
  debounce(async() => {
    await getCustomer();
  }, 500)
);

</script>
